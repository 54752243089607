import React from 'react'
import WorkingMap from './WorkingMap'
import { useJsApiLoader, useLoadScript } from '@react-google-maps/api'
import settings from '../../../settings/settings-development'
const MapWrapper = () => {
    <></>

    // const { isLoaded, loadError } = useLoadScript({
    //     id: 'google-map-script',
    //     googleMapsApiKey: settings?.googleMaps?.apiKey
    //   })
    // return (
    //     <div>
    //       { isLoaded ?  <WorkingMap /> : <div></div> }
    //     </div>
    // )
}

export default MapWrapper
